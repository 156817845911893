import { useEffect,useState,useRef } from "react";
import { getCardsForAreaClientLogged } from "../hooks/useMountScreen";
import apiAxios from "../services/api";
import { getDataClientLocalStorage, getTokenIsAuthenticatedClient, isAuthenticatedClient } from "../services/auth";
import Container from "react-bootstrap/Container";
import Row  from "react-bootstrap/Row";
import Col  from "react-bootstrap/Col";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Table } from 'react-bootstrap';
import { controleAbasAbertasLocalStorage, converterDecimal128ParaPrecoBrasil, criarUrlGoogleMapsDirect, getAbasAbertas, isLatitude , isLongitude } from "../util/util";
import { socketPingUsuarioLogado } from "../context/socket";
import Form from 'react-bootstrap/Form';
import { mountCards } from "../hooks/useMountScreen";
import Spinner from 'react-bootstrap/Spinner';
import { v4 as uuidv4 } from 'uuid';
 import { Button, Alert } from "react-bootstrap";
import { logoutClient } from "../services/auth";
//import SectionFormCarrosEdicaoCarros from "./sectionFormCarrosEdicaoCarros";
import MapLeafletForUsers from "./mapLeafletForUsers";
import getCoordinates from "../hooks/getCoordinates";
import SectionEstatisticaUsuario from "./SectionEstatisticaUsuario";
import SectionRotas from "./SectionRotas";




const SectionCardsClienteLogado = () => {

  const urlBaseGoogleMaps = 'https://www.google.com/maps/search/?api=1&query=';

    const [areaCardsClientLogado , setAreaCardsClientLogado] = useState(['No momento estão cadastrando estabelecimentos que vendem Vinhos']);
    const [nomeCliente , setNomeCliente] = useState('');
    const [tipoBuscaCategorias , setTipoBuscaCategorias] = useState(['Cervejas','Vinhos']);

    const [localizarApartirEndereco, setLocalizarApartirEndereco] = useState(true);
    const [establishments, setEstablishments] = useState([]);
    const [distanciaEmMetros, setDistanciaEmMetros] = useState('3000');
    const [location, setLocation] = useState({
      status: null,
      permissionState: null,
      latitude: null,
      longitude: null,
      message: '',
    });
    const refRadioCervejas = useRef(null);
    const refRadioVinhos = useRef(null);
    const refRadioCervejasVinhos = useRef(null);
    const refRadioEnderecoBusca = useRef(null);
    const refRadioLocAtualBusca = useRef(null);

    const refRadioDistancia3 = useRef(null);
    const refRadioDistancia5 = useRef(null);
    const refRadioDistancia8 = useRef(null);
    const [posicaoAtual, setPosicaoAtual] = useState({'latitude':null, 'longitude':null});
    

    const [spinnerBuscando,setSpinnerBuscando] = useState(true);
    const paragraphRef = useRef(null);

    const [estatisticaMediaProdutosRedor,setEstatisticaMediaProdutosRedor] = useState(null);
    const [estatisticaProdutosMiasClicadosNaRegiao,setEstatisticaProdutosMiasClicadosNaRegiao] = useState(null);
    const [cliente,setCliente] = useState(null);
    const [dadosMotorista,setDadosMotorista] = useState(null);
    const [valorTotalComissao,setValorTotalComissao] = useState('0,00');
    const [gastosComissao,setGastosComissao] = useState(null);
    const [codigoMotorista,setCodigoMotorista] = useState('--');
    const [isCheckedIsMotorista,setIsCheckedIsMotorista] = useState(false);
    const [estabelecimentosParceirosMotoristas,setEstabelecimentosParceirosMotoristas] = useState(null);
    const [matricula, setMatricula] = useState('');
  const [appTrabalho, setAppTrabalho] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isMotoristaCamposDisabled,setIsMotoristaCamposDisabled]= useState(false);
  
// Supondo que você tenha um estado inicial indicando se o motorista é parceiro ou não
const [isParceiro, setIsParceiro] = useState(false);



const handleParceriaChange = (event) => {
  event.preventDefault();

    // Validação dos campos
    if (matricula.trim().length < 4 || matricula.trim().length > 15) {
      setErrorMessage('Matrícula deve ter entre 4 e 15 caracteres.');
      return;
    }

    if (appTrabalho.trim().length < 4 || appTrabalho.trim().length > 15) {
      setErrorMessage('O campo "App que você trabalha" deve ter entre 4 e 15 caracteres.');
      return;
    }

    // Se todas as validações forem bem-sucedidas, limpar a mensagem de erro
    setErrorMessage('');
  


  var urlIsMotorista = "/usuario/isMotoristaApp";
 

  apiAxios.post(urlIsMotorista, {
    'matricula' : matricula,
    'nomeApp' : appTrabalho
   })
   .then((response) => {
    
    setCliente(response.data.usuario);
    
    setIsCheckedIsMotorista(response.data.usuario.isMotoristaAppTaxista);
    alert(response.data.message);
    
    consultaUsuario();
   });

  // Enviar a requisição para atualizar o status de parceria no backend
 
};
    useEffect(() => { 
      const posAtual = {
        'latitude':location.latitude, 'longitude':location.longitude
      };
      setPosicaoAtual(posAtual);
      executeSetIntervalGeolocation()

    },[location]);
  

function consultaUsuario(){

  var url = "/usuario/consultaUsuario";
 

  apiAxios.post(url, {
    'consulta' : true
   })
   .then((response) => {
    
    setCliente(response.data.usuario);

    if(response.data.usuario.isMotoristaAppTaxista){
      setDadosMotorista(response.data.dadosMotorista.estabelecimentos);
      setValorTotalComissao(response.data.dadosMotorista.valorTotalComissaoMotorista);
      setCodigoMotorista(response.data.dadosMotorista.codigoMotorista);
      setGastosComissao(response.data.dadosMotorista.gastosComissao);
      setMatricula(response.data.dadosMotorista.codigoMotorista);
      setAppTrabalho(response.data.dadosMotorista.nomeApp);
    }
    if(response.data.dadosMotorista.codigoMotorista && 
      response.data.dadosMotorista.codigoMotorista !== null &&
      response.data.dadosMotorista.codigoMotorista !== undefined){
      setIsMotoristaCamposDisabled(true);
    }
    
    setIsCheckedIsMotorista(response.data.usuario.isMotoristaAppTaxista);
    
    
    
   });

  
}

    useEffect(() => { 
      
      socketPingUsuarioLogado.connect();

      consultaUsuario();

      // Função para atualizar a localização
    const updateLocation = async () => {
      const locationData = await getCoordinates();
      setLocation(locationData);
    };


      const datClient = getDataClientLocalStorage();
     
      setNomeCliente(datClient.nome);
      
        const interval = setInterval(updateLocation, 5000);
      
        //funcao para detectar o fechamento da aba ou janela
   const handleUnload = async () =>{ 
    
    
    
    localStorage.removeItem('socketIdPingUsuarioLogado');
    socketPingUsuarioLogado.disconnect(true);
    clearInterval(interval);
    
    
   }

   window.addEventListener('beforeunload',handleUnload);

      window.scrollTo({
        top: paragraphRef.current.offsetTop,
        behavior: "smooth"
      });

      return () =>{ // return é a função de cleanup 
        
        socketPingUsuarioLogado.disconnect(true);
        localStorage.removeItem('socketIdPingUsuarioLogado');
        clearInterval(interval);
        window.removeEventListener('beforeunload',handleUnload);
        
       
      };
      
    },[]);  // https://blog.logrocket.com/solve-react-useeffect-hook-infinite-loop-patterns/

    

    const executeSetIntervalGeolocation = async () => { 

      

      const coordsClient = {
        'latitude':getDataClientLocalStorage().latitude , 
        'longitude':getDataClientLocalStorage().longitude,
      'email':getDataClientLocalStorage().email,
      'nome':getDataClientLocalStorage().nome,
      'celular':getDataClientLocalStorage().celular };

        //realizar a chamada direto do ping
        const arrayCategoriasEscolhidas = 
          (
        refRadioCervejas.current.checked ? 
        ['Cervejas']
        :
        (refRadioVinhos.current.checked ? 
        ['Vinhos']
        :
        ['Cervejas','Vinhos']
        )
        );
       
        setTipoBuscaCategorias(arrayCategoriasEscolhidas);

        const distanciaEscolhida = 
        (
          refRadioDistancia3.current.checked ?
          '3000'
          :
          (
            refRadioDistancia5.current.checked ?
            '5000'
            :
            '8000'
          )
        );

        setDistanciaEmMetros(distanciaEscolhida);

        const aPartirEndereco = 
        (
          refRadioEnderecoBusca.current.checked ?
          true
          :
          (refRadioLocAtualBusca.current.checked ?
            false 
            :
            true
          )
        );

        setLocalizarApartirEndereco(aPartirEndereco);


        const data = {

            'latitude' : (aPartirEndereco ? coordsClient.latitude : location.latitude) , 
            'longitude' : (aPartirEndereco ? coordsClient.longitude : location.longitude) ,
            'latitudeAtual' : location.latitude  ,
            'longitudeAtual' : location.longitude ,
            'distanciaMetros' : distanciaEscolhida,
            'produtos' : arrayCategoriasEscolhidas,
            'categorias' : arrayCategoriasEscolhidas,
            'clienteLogado' : true

        };
       
        setSpinnerBuscando(true);
        socketPingUsuarioLogado.emit("/pingUsuarioLogado", data);
          //socketPing.emit("/ping", data);

    }


    //socketPing.on('ping', data =>{ 
      socketPingUsuarioLogado.on('pingUsuarioLogado', data =>{
     if(data.publicacaoProdutos){

      console.log('OPAAAA RECEBEMOS PUBLICACAO DE PRODUTOS => '+ JSON.stringify(data.produtosPublicados));

     }else{ 
      setSpinnerBuscando(false);
      
      var arrayEstabelecimentosEclientes = [];
      var arrayProdutos = [];
      var verificaTemEstabelecimentos = (
                
        !data.erro && data.estabelecimentosPorCategoriaProdutosPing.length > 0   ?
        true
        :
        false
        );

        var verificaTemClientes = (
          
          !data.erro && data.usuariosPorCategoriaProdutosPing.length > 0   ?
          true
          :
          false
          );
          var arrayEstabelecimentosMotoristas = [];
        if(verificaTemEstabelecimentos){
      
          data.estabelecimentosPorCategoriaProdutosPing.forEach(async (estab) => { 

            if(estab.produtos_venda.length > 0 ){
              estab.produtos_venda.forEach(async (produto) => {
                arrayProdutos.push(produto);
              });
            }
      
            arrayEstabelecimentosEclientes.push(estab);

            if(estab.isParticipaRedeMotoristasAppTaxista && estab.isPlanoAtivo){
              arrayEstabelecimentosMotoristas.push(estab);
            }

          });

          
      
        }

        if(arrayEstabelecimentosMotoristas.length > 0){
          setEstabelecimentosParceirosMotoristas(arrayEstabelecimentosMotoristas);
        }

        if(verificaTemClientes){ 

    data.usuariosPorCategoriaProdutosPing.forEach(async (cliente) => { 
      
      if(cliente.produtos_venda.length > 0 ){
        cliente.produtos_venda.forEach(async (produto) => {
          arrayProdutos.push(produto);
        });
      }
      arrayEstabelecimentosEclientes.push(cliente);
    });

  }

 

         // const cardsForAreaClientLogado = getCardsForAreaClientLogged(arrayEstabelecimentosEclientes,null);
         const cardsForAreaClientLogado = mountCards(data,tipoBuscaCategorias);
          setAreaCardsClientLogado(cardsForAreaClientLogado);
        
          setEstablishments(arrayEstabelecimentosEclientes);
      
        if(data.estatisticaMediaProdutosRedor){
          setEstatisticaMediaProdutosRedor(data.estatisticaMediaProdutosRedor);
        }

        if(data.estatisticaProdutosMiasClicadosNaRegiao){
          setEstatisticaProdutosMiasClicadosNaRegiao(data.estatisticaProdutosMiasClicadosNaRegiao);
        }
        

     }
      
      
              });

     
              
     const buscarPorCategorias = event => { 
      


      const categoria = event.target.getAttribute("data-categoria"); 
      var categorias = [];
      if(categoria === 'Cervejas e Vinhos'){
        categorias.push('Cervejas');
        categorias.push('Vinhos');
         
      }
      else{
        categorias.push(categoria);
      }
      
      
      setTipoBuscaCategorias(categorias);
      console.log('SETOU COMO ? => '+categorias);
        var urlbuscarPorCategorias = "/usuario/buscarCategorias";
        apiAxios.post(urlbuscarPorCategorias, {
         
          'distanciaBusca' : distanciaEmMetros,
          'categoria' : categorias
         })
         .then((response) => {
        console.log(JSON.stringify(response));

        const estabelecimentos = response.data.estabelecimentos;
          if(response.data.estabelecimentos.length === 0){
            setAreaCardsClientLogado('No momento estão cadastrando estabelecimentos que vendem '+categorias);
            setEstablishments([]);
          }
          else{

            const cardsForAreaClientLogado = getCardsForAreaClientLogged(estabelecimentos,categorias);
    
            setAreaCardsClientLogado(cardsForAreaClientLogado);
          
            setEstablishments(estabelecimentos);

          }
       

          
        }).catch((erro) => {
          
        });




     }

     const sair = event => {

      socketPingUsuarioLogado.disconnect(true);
      localStorage.removeItem('socketIdPingUsuarioLogado');
      logoutClient();
      window.location.reload(false);
    
    
    }

    const containerStyle = {
      backgroundColor: '#e6f7ff',
      border: '2px solid #1890ff',
      borderRadius: '8px',
      padding: '15px',
      margin: '10px 0',
      display: 'flex',
      alignItems: 'center'
    };
  
    const inputStyle = {
      marginRight: '10px'
    };
  
    const labelStyle = {
      fontWeight: '600',
      color: '#0050b3',
      marginBottom: '0'
    };

     return (
        <>
       
        
        { (
            isLatitude (getDataClientLocalStorage().latitude) &&
            isLongitude (getDataClientLocalStorage().longitude)

            ?
            
            <>
             <section key='22233311' className="text-center" style={{"padding-top": "3rem", "padding-bottom": "1rem"}}> 
        <Container>

        <Row className="justify-content-between align-items-center mb-3">
      <Col className="d-flex justify-content-start">
        <h5 className="text-muted">Dica do Dia: Experimente a cerveja IPA local!</h5> {/* Título com a dica de cerveja ou vinho */}
      </Col>
      <Col className="d-flex justify-content-end">
        <Button variant="danger" onClick={sair} size="sm" type="submit" className="px-4">Sair</Button>
      </Col>
    </Row>

        <Tabs
      defaultActiveKey="mapa"
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey="mapa" title="Mapa">
      <h2 className="mb-5" ref={paragraphRef}>{nomeCliente}, Descubra as melhores promoções de cervejas e vinhos perto de você e transforme qualquer ocasião em um brinde especial!</h2>
      <h4>Encontre no Mapa:</h4> {/* Título para a aba Mapa */}
      <Row>
  <Col className="md-2 mb-3">
     
     </Col>

     <Col className="md-2 mb-3">
     
     {['radio'].map((type) => (
        <div key={`inline-${type}`} className="mb-3" style={containerStyle}>
          <Form.Check
            inline
            label={<span style={labelStyle}>A partir da suaLocalização</span>}
            name="enderecoBusca"
            type={type}
            id={`inline-${type}-7`}
            value='localizacaoAtual'
            ref={refRadioLocAtualBusca}
            //onChange={onChangeRadioCategoriasBusca}
            //checked={tipoBuscaCategorias.includes('Cervejas')}
            style={inputStyle}
            onClick={()=>setSpinnerBuscando(true)}
          />
          <Form.Check
            inline
            label={<span style={labelStyle}>A partir do seu Endereço</span>}
            name="enderecoBusca"
            type={type}
            id={`inline-${type}-8`}
            value='localizacaoEndereco'
            ref={refRadioEnderecoBusca}
            //onChange={onChangeRadioCategoriasBusca}
            //checked={tipoBuscaCategorias.includes('Vinhos')}
            style={inputStyle}
            onClick={()=>setSpinnerBuscando(true)}
          />
         
        
        </div>
      ))}

     </Col>
          
     

     <Col className="md-2 mb-3">
     
     </Col>

     </Row>
        <Row>
        {/*<ButtonGroup aria-label="Botões para escolher entre cervejas e vinhos">
      <Button variant="warning" 
      onClick={buscarPorCategorias} 
      data-categoria="Cervejas"
      >Cervejas</Button>
      <Button variant="secondary" 
      onClick={buscarPorCategorias} 
      data-categoria="Cervejas e Vinhos"
      >Cervejas e Vinhos</Button>
      <Button variant="success" style={{"background-color": "#800000"}} 
      onClick={buscarPorCategorias} 
      data-categoria="Vinhos"
      >Vinhos</Button>
    </ButtonGroup>*/}
   

<Col className="md-2 mb-3">
      
      </Col>
      <Col className="md-2 mb-3">
      {['radio'].map((type) => (
        <div key={`inline-${type}`} className="mb-3" style={containerStyle}>
          <Form.Check
            inline
            label={<span style={labelStyle}>Cervejas</span>}
            name="categoriasBusca"
            type={type}
            id={`inline-${type}-1`}
            value='Cervejas'
            ref={refRadioCervejas}
            //onChange={onChangeRadioCategoriasBusca}
            //checked={tipoBuscaCategorias.includes('Cervejas')}
            style={inputStyle}
            onClick={()=>setSpinnerBuscando(true)}
          />
          <Form.Check
            inline
            label={<span style={labelStyle}>Vinhos</span>}
            name="categoriasBusca"
            type={type}
            id={`inline-${type}-2`}
            value='Vinhos'
            ref={refRadioVinhos}
            //onChange={onChangeRadioCategoriasBusca}
            //checked={tipoBuscaCategorias.includes('Vinhos')}
            style={inputStyle}
            onClick={()=>setSpinnerBuscando(true)}
          />
          <Form.Check
            inline
            label={<span style={labelStyle}>Cervejas e Vinhos</span>}
            name="categoriasBusca"
            type={type}
            id={`inline-${type}-3`}
            value='Cervejas e Vinhos' 
            ref={refRadioCervejasVinhos}
            //onChange={onChangeRadioCategoriasBusca}
            //checked={(tipoBuscaCategorias.includes('Vinhos') && tipoBuscaCategorias.includes('Cervejas'))}
            style={inputStyle}
            onClick={()=>setSpinnerBuscando(true)}
            
          />
        
        </div>
      ))}
      </Col>
      <Col className="md-2 mb-3">
     
      </Col>
  </Row>
  <Row>
  <Col className="md-2 mb-3">
     
     </Col>
     <Col className="md-2 mb-3">
     {['radio'].map((type) => (
        <div key={`inline-${type}`} className="mb-3" style={containerStyle}>
          <Form.Check
            inline
            label={<span style={labelStyle}>Até 3 KM</span>}
            name="distanciaBusca"
            type={type}
            id={`inline-${type}-4`}
            value='3000'
            ref={refRadioDistancia3}
            style={inputStyle}
            onClick={()=>setSpinnerBuscando(true)}
          />
          <Form.Check
            inline
            label={<span style={labelStyle}>Até 5 KM</span>}
            name="distanciaBusca"
            type={type}
            id={`inline-${type}-5`}
            value='5000'
            ref={refRadioDistancia5}
            style={inputStyle}
            onClick={()=>setSpinnerBuscando(true)}
          />
          <Form.Check
            inline
            label={<span style={labelStyle}>Até 8 KM</span>}
            name="distanciaBusca"
            type={type}
            id={`inline-${type}-6`}
            value='8000'
            ref={refRadioDistancia8}
            style={inputStyle}
            onClick={()=>setSpinnerBuscando(true)}
          />
        
        </div>
      ))}
     </Col>
     <Col className="md-2 mb-3">
     
     </Col>
  </Row>

  <Row>
  <Col className="md-2 mb-3">
    
     </Col>
     <Col className="md-2 mb-3">
     {(spinnerBuscando ?
      ( <Spinner animation="border" role="status">
      <span className="visually-hidden">Buscando...</span>
    </Spinner>)
    :
    ''
     )}
     </Col>
     <Col className="md-2 mb-3">
     
     </Col>
  </Row>

  {areaCardsClientLogado}

  <section key='22233390' className="text-center" style={{"padding-top": "1rem", "padding-bottom": "3rem", "height": "100vh"}}>   
            <Container>             
            
              

<MapLeafletForUsers     
            arrayMarkers={establishments}
            centroMapaPosicaoUser={[
          (localizarApartirEndereco ? getDataClientLocalStorage().latitude : (location.latitude !== null ? location.latitude : getDataClientLocalStorage().latitude ) ),
          (localizarApartirEndereco ? getDataClientLocalStorage().longitude : (location.longitude !== null ? location.longitude : getDataClientLocalStorage().longitude ) )
            ]}
            posicaoUser={[
              getDataClientLocalStorage().latitude,
              getDataClientLocalStorage().longitude
            ]}
            dataUserCenter={getDataClientLocalStorage()}
            mapParaEstabelecimento={false}
            raioCirculo = {distanciaEmMetros}
            
            />


               
          
</Container>

</section>


      </Tab>
      <Tab eventKey="profile" title="Estatísticas">
      <h3>Melhores Preços na Sua Região: Comparativo de Bebidas</h3>
      <p>Veja os preços mais competitivos de cervejas e vinhos ao seu redor e os produtos mais desejados pelos consumidores na sua região.</p>
      {(estatisticaMediaProdutosRedor || estatisticaProdutosMiasClicadosNaRegiao ? <SectionEstatisticaUsuario 
estatValorMedioProdutosRegiao={estatisticaMediaProdutosRedor}
estatProdutosMiasClicadosNaRegiao={estatisticaProdutosMiasClicadosNaRegiao}
/> : '')}
      </Tab>
      <Tab eventKey="longer-tab" title="Rotas do Vinho e Cerveja">
      <h4>Explore as Rotas de Vinho e Cerveja:</h4>
      {
    (
      establishments !== null && establishments.length > 0 ? 
      <SectionRotas estabelecimentos={establishments} origin={{ lat: posicaoAtual.latitude, lng: posicaoAtual.longitude }} /> 
      :
       ''
    )
    }
      </Tab>
      <Tab eventKey="motorista" title="Motorista Aplicativos">
      <Container>
    {/* Título da Aba */}
    <h3 className="mt-4">Parceria com Motoristas de Aplicativos</h3>
    <p>Nesta aba, você encontrará informações sobre como funciona a parceria da GeoGet com motoristas de aplicativos e como você pode se beneficiar.</p>

    {/* Código do Motorista Parceiro */}
    <Form.Group className="mt-3">
      <Form.Label className="fw-bold">Seu Código de Motorista Parceiro GeoGet:</Form.Label>
      <div className="p-3 mb-2 bg-warning text-dark">
        <span className="fw-bold fs-5">{codigoMotorista}</span> {/* Exemplo de código destacado */}
      </div>
    </Form.Group>

    {/* Descrição do Funcionamento da Parceria */}
    <p className="mt-4">
      Quando um motorista de aplicativo leva um cliente até um estabelecimento parceiro GeoGet e o cliente consome no local, ao pagar a conta, ele informa o código do motorista do aplicativo. 
      O estabelecimento registra esse código, e o motorista ganha 3% do valor da conta paga, que é acumulativo até o máximo de R$25,00 por vez. 
      Ao atingir R$25,00, o motorista deve gastar esse valor em qualquer estabelecimento parceiro GeoGet.
      Para validar a corrida, o cliente e o estabelecimento devem confirmar a corrida e o código do motorista no momento do pagamento.
    </p>
    <Alert variant="warning" className="mt-3">
        Atenção: os Estabelecimentos irão confirmar esses dados para dar a sua comissão por indicações.
      </Alert>
   
     {/* Exibir mensagem de erro, se houver */}
     {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

    <Container>
      <Form onSubmit={handleParceriaChange}>
        <Row className="mb-3">
          <Col xs={12}>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
        type="checkbox"
        id="parceria-motorista"
        label="Sou motorista de aplicativo e desejo ser parceiro GeoGet"
        checked={isCheckedIsMotorista}
      />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12} md={6}>
            <Form.Group controlId="formMatricula">
              <Form.Label>Matrícula, Id ou Cód Motorista fornecido pelo App</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Digite sua matrícula" 
                value={matricula}
                maxLength={15}
                onChange={(e) => setMatricula(e.target.value)}
                required
                disabled={isMotoristaCamposDisabled}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="formAppTrabalho">
              <Form.Label>App que você trabalha (se taxista, preencha "taxista")</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Digite o app que você trabalha" 
                value={appTrabalho}
                maxLength={15}
                onChange={(e) => setAppTrabalho(e.target.value)}
                required
                disabled={isMotoristaCamposDisabled}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="d-flex justify-content-end">
            <Button variant="primary" type="submit" disabled={isMotoristaCamposDisabled}>
              Cadastrar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>

    {/* Tabela com Ganhos */}
    <h4 className="mt-5">Histórico de Ganhos</h4>
    <Table responsive striped bordered hover>
      <thead>
        <tr>
          <th>Data</th>
          <th>Nome do Estabelecimento</th>
          <th>Valor da Conta</th>
          <th>Valor Ganho</th>
        </tr>
      </thead>
      <tbody>
       
          {
             (dadosMotorista ?   
            
              dadosMotorista.map((dado)=>(
                <tr>
                <td>{dado.data}</td>
                <td>{dado.nomeEstabelecimento}</td>
                <td>{dado.valorGastoEstabelecimento}</td>
                <td>{dado.valorComissaoMotorista}</td>
                </tr>
              ))
              :
              <tr>
              <td>--</td>
              <td>--</td>
              <td>--</td>
              <td>--</td>
              </tr>
            
            )
             
              
            
          }
         
       
        {/* Linhas de exemplo, você pode substituir pelos dados dinâmicos */}
      </tbody>
    </Table>


    <h4 className="mt-5">Histórico de Gastos de sua Comissão</h4>
    <Table responsive striped bordered hover>
      <thead>
        <tr>
          <th>Nome do Estabelecimento</th>
          <th>Valor da sua Conta</th>
          <th>Valor desc da sua Comissão</th>
        </tr>
      </thead>
      <tbody>
       
          {
             (gastosComissao ?   
            
              gastosComissao.map((gasto)=>(
                <tr>
                <td>{gasto.nomeEstabelecimento}</td>
                <td>{gasto.totalConta}</td>
                <td>{gasto.gastoComissao}</td>
                </tr>
              ))
              :
              <tr>
              <td>--</td>
              <td>--</td>
              <td>--</td>
              </tr>
            
            )
             
              
            
          }
         
       
        {/* Linhas de exemplo, você pode substituir pelos dados dinâmicos */}
      </tbody>
    </Table>
   
    {/* Valor Total Acumulado */}
    <Form.Group className="mt-4">
      <Form.Label className="text-success fw-bold">Valor Total Acumulado para Gastar: {valorTotalComissao}</Form.Label>
    </Form.Group>

    {/* Estabelecimentos Parceiros na Região */}
    <h4 className="mt-5">Estabelecimentos Parceiros em sua Região</h4>
    <div>
      {/* Exemplos de estabelecimentos, que podem ser gerados dinamicamente */}

      {

        (estabelecimentosParceirosMotoristas ? 

          estabelecimentosParceirosMotoristas.map((estab) => {
         // Constrói a query para o Google Maps
  const query = `${estab.rua},${estab.numero} - ${estab.bairro}, ${estab.cidade} - ${estab.estado}, ${estab.cep}`;
  
  // Codifica a URL para garantir que os espaços e caracteres especiais sejam tratados corretamente
  const urlGoogleMapss = `${urlBaseGoogleMaps}${encodeURIComponent(query)}`;
  return (
    <p key={estab.id}>
      Distância: {estab.distanciaEmKilometros.toFixed(2)} km - Nome: {estab.nome_estabelecimento} - Tipo: {estab.tipo_estabelecimento} - 
      <a href={urlGoogleMapss} 
        target="_blank"
        rel="noopener noreferrer"
      >
        Ir até lá
      </a>
    </p>
  );


    
})
          :
          ''


        )

        
      
      }

      {/* Exibir estabelecimentos parceiros */}
    </div>
  </Container>
      </Tab>
    </Tabs>



                            </Container>
        </section>

           

      



          </>
            :

            ''
        )  
                   
                }
        
       
        </>
     );

}

export default SectionCardsClienteLogado;