import axios from "axios";
import { getTokenAdminLogado, getTokenIsAuthenticatedClient, getTokenIsAuthenticatedEstabelecimento, getTokenParceiroLogado, logout, logoutClient } from "./auth";
import env from 'react-dotenv';

const bUrl = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 
    //Dev
    env.ADDRESS_API_DESENV
    //'http://localhost:3333/'
    :
    //Prd
    env.ADDRESS_API_PRD
    //'http://www.geoget.com.br:4000/'
    ) ;

const apiAxios = axios.create({ 
  baseURL: 'https://api.geoget.com.br',
  withCredentials: false
});

apiAxios.interceptors.request.use(async config => { 
  //console.log(JSON.stringify(config));

  const urlRequest = config.url;

  if(
    urlRequest === '/usuario/getEstabelecimentosProximos' ||
    urlRequest === '/usuario/buscarCategorias' ||
    urlRequest === '/produto/carroNovoUsuario' ||
    urlRequest === '/produto/atualizarCarroUsuario' ||
    urlRequest === '/produto/removerCarroUsuario' ||
    urlRequest === '/produto/consultarCliente'||
    urlRequest === '/usuario/isMotoristaApp' ||
    urlRequest === '/usuario/consultaUsuario'
    ){

    const token = getTokenIsAuthenticatedClient();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  }
  else if(
    urlRequest === '/estabelecimento/downloadQRCode' ||
    urlRequest === '/produto/consultar' ||
    urlRequest === '/produto/novo' ||
    urlRequest === '/produto/atualizar' ||
    urlRequest === '/produto/remover' ||
    urlRequest === '/produto/carroNovo' ||
    urlRequest === '/produto/removerCarro' ||
    urlRequest === '/produto/atualizarCarro' ||
    urlRequest === '/estabelecimento/getTextAiEstabelecimentoLogadoChat' ||
    urlRequest === '/estabelecimento/utilizacaoSaldoMotorista' || 
    urlRequest === '/estabelecimento/comissaoIndicacaoMotorista' ||
    urlRequest === '/estabelecimento/isParticipaRedeMotoristasAppTaxista' ||
    urlRequest === '/estabelecimento/consultaEstabelecimento' ||
    urlRequest === '/estabelecimento/consultaMotorista'
    
    ){

    const token = getTokenIsAuthenticatedEstabelecimento();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  }
  else if(
    urlRequest === '/parceiro/novoEstabelecimento' ||
    urlRequest === '/parceiro/consultaInformacoes' ||
    urlRequest === '/parceiro/removerEstabelecimento'
  ){
    const token = getTokenParceiroLogado();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  else if(
    urlRequest === '/admin/consultaInicial' ||
    urlRequest === '/admin/addPlanoParceiro' ||
    urlRequest === '/admin/addPlanoEstabelecimento' ||
    urlRequest === '/admin/bloquearParceiro' || 
    urlRequest === '/admin/desbloquearParceiro' ||
    urlRequest === '/admin/atualizaDadosParceiro' ||
    urlRequest === '/admin/desvincularPlanoEstabelecimento' ||
    urlRequest === '/admin/bloquearDesbloquearEstabelecimento' ||
    urlRequest === '/admin/atualizaLatitudeLongitudeEstabelecimento' ||
    urlRequest === '/admin/addPlanoEstabelecimento' ||
    urlRequest === '/admin/atualizaDadosEstabelecimento' ||
    urlRequest === '/admin/atualizarDadosUsuario' ||
    urlRequest === '/admin/atualizaLatitudeLongitudeUsuario' ||
    urlRequest === '/admin/changeQtdeDiasInicioGratisEstabelecimento' ||
    urlRequest === '/admin/changeQtdeDiasInicioGratisParceiro' ||
    urlRequest === '/admin/deletar' ||
    urlRequest === '/admin/changeQtdeMaxTokensChatEstabOpenAi' ||
    urlRequest === '/admin/changePorcentagemComissaoMotoristas' ||
    urlRequest === '/admin/alterarStatusMotorista' ||
    urlRequest === '/admin/alterarDadosMotorista' ||
    urlRequest === '/admin/consultarComissoesMotorista' ||
    urlRequest === '/admin/consultarProdutosEstabelecimento' ||
    urlRequest === '/admin/atualizaContextGeminiEstabelecimento'
  ){
    const token = getTokenAdminLogado();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  
  return config;
});

apiAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    console.log('ERROR NA API => '+JSON.stringify(error));
    if (error.status !== null && error.status !== 'undefined' && error.status === 401) {
     
       //TODO - - aqui que poder ser feito a renovação do token, sem que o usuario precise realizar novamente o login
      
       if(
        error.config.url === '/usuario/getEstabelecimentosProximos' ||
        error.config.url === '/usuario/buscarCategorias'){
        logoutClient();
       }
       else if(
        error.config.url === '/estabelecimento/downloadQRCode' ||
        error.config.url === '/produto/novo' ||
        error.config.url === '/produto/atualizar' ||
        error.config.url === '/produto/remover' ||
        error.config.url === '/produto/consultar'
        ){
        logout();
       }
    
      }
     
    return Promise.reject(error);
  }
);

export default apiAxios;